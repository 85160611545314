import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './tos.css';

const TOS = () => {
    const { t } = useTranslation('tos');

    return (
        <Container className="tos-container mt-5">
            <h1 className='mt-5'>{t('Titulo')}</h1>
            <p>{t('Introduccion')}</p>
            <h2>{t('Seccion1Titulo')}</h2>
            <p>{t('Seccion1Contenido')}</p>
            <h2>{t('Seccion2Titulo')}</h2>
            <p>
                {t('Seccion2Contenido')}
                <Link to="/privacy">{t('PoliticaPrivacidad')}</Link>. {t('Seccion2ContenidoParte2')}
            </p>
            <h2>{t('Seccion3Titulo')}</h2>
            <p>{t('Seccion3Contenido')}</p>
            <h2>{t('Seccion4Titulo')}</h2>
            <p>{t('Seccion4Contenido')}</p>
            <h2>{t('Seccion5Titulo')}</h2>
            <p>{t('Seccion5Contenido')}</p>
            <h2>{t('Seccion6Titulo')}</h2>
            <p>{t('Seccion6Contenido')}</p>
            <h2>{t('Seccion7Titulo')}</h2>
            <p>{t('Seccion7Contenido')}</p>
            <h2>{t('Seccion8Titulo')}</h2>
            <p>{t('Seccion8Contenido')}</p>
            <h2>{t('Seccion9Titulo')}</h2>
            <p>{t('Seccion9Contenido')}</p>
            <h2>{t('Seccion10Titulo')}</h2>
            <p>{t('Seccion10Contenido')}</p>
        </Container>
    );
};

export default TOS;
