import React from 'react';
import { useTranslation } from 'react-i18next';
import './legal.css';
import { Container } from 'react-bootstrap';

const Legal = () => {
  const { t } = useTranslation('legal');

  return (
    <Container className='legal-container mt-5'>
      <div className="legal mt-5">
      <h1 className='mt-5'>{t('Titulo')}</h1>
      <p>{t('Introduccion')}</p>
      <ul>
        <li><u>{t('Titular')}:</u> Javier Cordero Muñiz</li>
        <li><u>{t('NIF')}:</u> 52063776X</li>
        <li><u>{t('Domicilio')}:</u> Calle Salamanca 16, 28020, Madrid, Madrid.</li>
        <li><u>{t('CorreoElectronico')}:</u> corderoj@hotmail.es</li>
        <li><u>{t('Telefono')}:</u> +34 638 25 30 17</li>
        <li><u>{t('Telegram')}:</u> @Saries1</li>
      </ul>
      <p>{t('Conclusion')}</p>
    </div>
    </Container>
  );
};

export default Legal;
