import React from 'react';
import { Container } from 'react-bootstrap'; 
import { useTranslation } from 'react-i18next';
import './Cookies.css';

const Cookies = () => {
  const { t } = useTranslation('Cookies');

  return (
    <Container className='cookies-container mt-5'>
      <div className="Cookies mt-5">
        <h1>{t('Titulo')}</h1>
        <p>{t('Contenido1')}</p>
        <p>{t('Contenido2')}</p>
        <p>{t('Conclusion')}</p>
      </div>
    </Container>
  );
};

export default Cookies;
