import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './privacy.css';

const Privacy = () => {
    const { t } = useTranslation('privacy');

    return (
        <Container className="privacy-container mt-5">
            <h1 className="mt-5">{t('Titulo')}</h1>
            <p>{t('Introduccion')}</p>
            <h2>{t('Seccion1Titulo')}</h2>
            <p>{t('Seccion1Contenido')}</p>
            <ul>
                <li><u>{t('Seccion1Info1Titulo')}</u>: {t('Seccion1Info1')}</li>
                <li><u>{t('Seccion1Info2Titulo')}</u>: {t('Seccion1Info2')}</li>
                <li><u>{t('Seccion1Info3Titulo')}</u>: {t('Seccion1Info3')}</li>
                <li><u>{t('Seccion1Info4Titulo')}</u>: {t('Seccion1Info4')}</li>
            </ul>
            <h2>{t('Seccion2Titulo')}</h2>
            <p>{t('Seccion2Contenido')}</p>
            <ul>
                <li><u>{t('Seccion2Info1Titulo')}</u>: {t('Seccion2Info1')}</li>
                <li><u>{t('Seccion2Info2Titulo')}</u>: {t('Seccion2Info2')}</li>
                <li><u>{t('Seccion2Info3Titulo')}</u>: {t('Seccion2Info3')}</li>
            </ul>
            <h2>{t('Seccion3Titulo')}</h2>
            <p>{t('Seccion3Contenido')}</p>
            <ul>
                <li><u>{t('Seccion3Info1Titulo')}</u>: {t('Seccion3Info1')}</li>
                <li><u>{t('Seccion3Info2Titulo')}</u>: {t('Seccion3Info2')}</li>
                <li><u>{t('Seccion3Info3Titulo')}</u>: {t('Seccion3Info3')}</li>
            </ul>
            <h2>{t('Seccion4Titulo')}</h2>
            <p>{t('Seccion4Contenido')}</p>
            <ul>
                <li><u>{t('Seccion4Info1Titulo')}</u>: {t('Seccion4Info1')}</li>
                <li><u>{t('Seccion4Info2Titulo')}</u>: {t('Seccion4Info2')}</li>
            </ul>
            <h2>{t('Seccion5Titulo')}</h2>
            <p>{t('Seccion5Contenido')}</p>
            <ul>
                <li><u>{t('Seccion5Info1Titulo')}</u>: {t('Seccion5Info1')}</li>
                <li><u>{t('Seccion5Info2Titulo')}</u>: {t('Seccion5Info2')}</li>
                <li><u>{t('Seccion5Info3Titulo')}</u>: {t('Seccion5Info3')}</li>
                <li><u>{t('Seccion5Info4Titulo')}</u>: {t('Seccion5Info4')}</li>
                <li><u>{t('Seccion5Info5Titulo')}</u>: {t('Seccion5Info5')}</li>
            </ul>
            <p>{t('Seccion5Contacto')}</p>
            <h2>{t('Seccion6Titulo')}</h2>
            <p>{t('Seccion6Contenido')}</p>
            <h2>{t('Seccion7Titulo')}</h2>
            <p>{t('Seccion7Contenido')}</p>
            <h2>{t('Seccion8Titulo')}</h2>
            <p>{t('Seccion8Contenido')}</p>
        </Container>
    );
};

export default Privacy;
