import CookiesES from './traducciones/es/Cookies.json';
import CookiesEN from './traducciones/en/Cookies.json';
import legalES from './traducciones/es/legal.json';
import legalEN from './traducciones/en/legal.json';
import recuperacionES from './traducciones/es/recuperacion.json';
import recuperacionEN from './traducciones/en/recuperacion.json';
import cuentasES from './traducciones/es/cuentas.json';
import cuentasEN from './traducciones/en/cuentas.json';
import entradasES from './traducciones/es/entradas.json';
import entradasEN from './traducciones/en/entradas.json';
import privacyES from './traducciones/es/privacy.json';
import privacyEN from './traducciones/en/privacy.json';
import tosES from './traducciones/es/tos.json';
import tosEN from './traducciones/en/tos.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import homeEN from './traducciones/en/home.json';
import homeES from './traducciones/es/home.json';
import shopEN from './traducciones/en/shop.json';
import shopES from './traducciones/es/shop.json';
import navbarEN from './traducciones/en/navbar.json';
import navbarES from './traducciones/es/navbar.json';
import registroEN from './traducciones/en/registro.json';
import registroES from './traducciones/es/registro.json';
import perfilEN from './traducciones/en/perfil.json';
import perfilES from './traducciones/es/perfil.json';
import editarEN from './traducciones/en/editar.json';
import editarES from './traducciones/es/editar.json';
import galeriaEN from './traducciones/en/galeria.json';
import galeriaES from './traducciones/es/galeria.json';
import lectorEN from './traducciones/en/lector.json';
import lectorES from './traducciones/es/lector.json';
import masinfoEN from './traducciones/en/masinfo.json';
import masinfoES from './traducciones/es/masinfo.json';
import loginEN from './traducciones/en/login.json';
import loginES from './traducciones/es/login.json';
import tablaResumenEN from './traducciones/en/tablaResumen.json';
import tablaResumenES from './traducciones/es/tablaResumen.json';

const resources = {
  en: {
home: homeEN,
    shop: shopEN,
    navbar: navbarEN,
    registro: registroEN,
    perfil: perfilEN,
    login: loginEN,
    editar: editarEN,
    galeria: galeriaEN,
    lector: lectorEN,
    masinfo: masinfoEN,
    tos: tosEN,
    privacy: privacyEN,
    entradas: entradasEN,
    cuentas: cuentasEN,
    recuperacion: recuperacionEN,
    legal: legalEN,
    Cookies: CookiesEN,
    tablaResumen: tablaResumenEN
  },
  es: {
home: homeES,
    shop: shopES,
    navbar: navbarES,
    registro: registroES,
    perfil: perfilES,
    login: loginES,
    editar: editarES,
    galeria: galeriaES,
    lector: lectorES,
    masinfo: masinfoES,
    tos: tosES,
    privacy: privacyES,
    entradas: entradasES,
    cuentas: cuentasES,
    recuperacion: recuperacionES,
    legal: legalES,
    Cookies: CookiesES,
    tablaResumen: tablaResumenES
  }
};

const savedLanguage = localStorage.getItem('language') || 'es';

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: savedLanguage,
    ns: ['home', 'shop', 'navbar', 'registro', 'perfil', 'login', 'editar', 'galeria', 'lector', 'masinfo', 'tos', 'privacy', 'entradas', 'cuentas', 'recuperacion', 'legal', 'Cookies'],
    defaultNS: 'home',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
