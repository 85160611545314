import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import './Login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [enviado, setEnviado] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation('login');
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (token) {
            navigate("/perfil");
        }
    }, [token, navigate]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setEnviado(true);

        const formlogin = {
            email,
            password
        };

        try {
            const res = await axios.post(`https://api.elplanmadrid.org/login`, formlogin);
            if (res.status === 200) {
                const token = res.data.token;
                localStorage.setItem('token', token);
                // Emitir el evento de login
                window.dispatchEvent(new Event('login'));
                navigate("/perfil");
            }
        } catch (error) {
            console.error(t('login_invalido'), error);
            if (error.response && (error.response.status === 401 || error.response.status === 404)) {
                setError(t('UsuarioContraseñaNoValidos'));
            } else {
                setError(t('ErrorInterno'));
            }
        }
    };

    const handleRegistro = () => {
        navigate("/registro");
    };

    const handleReset = () => {
        navigate("/recuperacion");
    };

    return (
        <Form onSubmit={handleSubmit}>
            <div className='pagina-login'>
                <div className="row justify-content-center">
                    <div className='col-md-4 mx-auto'>
                        <Form.Group className="mb-3 mt-5" controlId="email">
                            <Form.Label className="fw-bold">{t('Email')}:</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder={t('Email')}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="password">
                            <Form.Label className="fw-bold">{t('Contraseña')}:</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder={t('Contraseña')}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </Form.Group>

                        {enviado && error && <p className="text-danger">{error}</p>}

                        <Button style={{ backgroundColor: '#230B29', border: 'none' }} variant="primary" type="submit">{t('IniciarSesion')}</Button>

                        <div className="mt-3">
                            <p>{t('RecuperarContraseña')}</p>
                            <Button
                                className="btn btn-primary mb-3"
                                onClick={handleReset}
                                style={{ backgroundColor: '#230B29', border: 'none' }}
                            >
                                {t('Reiniciala')}
                            </Button>
                            <p>
                                <strong>{t('NoTienesCuentaParte1')}</strong>
                            </p>

                            <Button className="btn btn-primary" onClick={handleRegistro} style={{ backgroundColor: '#230B29', border: 'none' }}>{t('NoTienesCuentaParte2')}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
};

export default Login;
