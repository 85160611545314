import './Lector.css';
import React, { useState, useEffect } from "react";
import { QrReader } from "react-qr-reader";
import axios from "axios";
import { Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from "react-router-dom";
import { checkUserRole } from '../../hooks/customhooks';
import { useTranslation } from 'react-i18next';

const Lector = () => {
  const { t } = useTranslation('lector');
  const [startScan, setStartScan] = useState(true);
  const [loadingScan, setLoadingScan] = useState(false);
  const [data, setData] = useState(null);
  const [errorV, setErrorV] = useState(null);
  const [errorL, setErrorL] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  useEffect(() => {
    const verifyrole = async () => {
      const okrole = await checkUserRole(2, token);

      if (!okrole) {
        navigate('/error');
      }
    };

    verifyrole();
  }, [navigate, token]);

  const handleScan = async (scanData) => {
    if (scanData) {
      setLoadingScan(true);
      try {
        const response = await axios.post('https://api.elplanmadrid.org/validarqr', { 'texto': String(scanData) });
        setData(response.data.res);
        setStartScan(false);
        setLoadingScan(false);
      } catch (error) {
        setStartScan(false);
        setLoadingScan(false);
        if (error.response) {
          switch (error.response.status) {
            case 404:
              setErrorL(t('entrada_no_encontrada'));
              break;
            case 409:
              setErrorL(t('entrada_ya_validada'));
              break;
            case 403:
              setErrorL(t('qr_no_valido'));
              break;
            default:
              setErrorL(t('error_interno'));
          }
        } else {
          setErrorL(t('error_interno'));
        }
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleYes = async () => {
    try {
      await axios.post('https://api.elplanmadrid.org/aceptar', { "entrada": data.entrada_id });
      window.location.reload();
    } catch {
      setErrorV(t('error_interno'));
    }
  };

  const handleNo = () => {
    window.location.reload();
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', options);
  };

  return (
    <div className="lector-container">
      <div className="App container mt-5">
        {startScan && (
          <div className="d-flex justify-content-center mt-3">
            <div style={{ width: '300px', height: '300px', position: 'relative' }}>
              <h1 className='titulo mt-5'>{t('lector_qr')}</h1>
              <QrReader
                constraints={{ facingMode: "environment" }}
                delay={300}
                onResult={(result, error) => {
                  if (result) {
                    handleScan(result);
                  }
                  if (error) {
                    handleError(error);
                  }
                }}
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          </div>
        )}
        {errorL && (
          <div className="msjerror text-center mt-5">
            <p className="text-danger">{errorL}</p>
            <Button variant="danger" onClick={handleNo}>{t('refrescar')}</Button>
          </div>
        )}

        {data && (
          <div className="infoentrada text-center mt-3">
            <h1>{t('info')}</h1>
            <p><strong>{t('username')}:</strong> {data.username}</p>
            <p><strong>{t('telegram')}:</strong> {data.telegram}</p>
            <p><strong>{t('evento')}:</strong> {data.evento}</p>
            <p><strong>{t('fecha')}:</strong> {formatDate(data.fecha)}</p>
            <p><strong>{t('nacimiento')}:</strong> {formatDate(data.nacimiento)}</p>
            <div className="mt-3">
              <Button variant="success" onClick={handleYes}>{t('aceptar')}</Button>
              <Button className="ms-2" variant="danger" onClick={handleNo}>{t('rechazar')}</Button>
            </div>
            {errorV && <p className="text-danger">{errorV}</p>}
          </div>
        )}
        {loadingScan && <p className="text-center">{t('loading')}</p>}
      </div>
    </div>
  );
};

export default Lector;
