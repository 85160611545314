import React from 'react';
import { useTranslation } from 'react-i18next';
import './MasInfo.css';

const MasInfo = () => {
  const { t } = useTranslation('masinfo');

  return (
    <div className="container mt-5">
      <h1 className="mt-5">{t('en_construccion')}</h1>
      <img src='https://media.tenor.com/0OC2S5gxafIAAAAi/fred-mopping-fred.gif' alt={t('imagen_alt')} />
    </div>
  );
}

export default MasInfo;
