import './Error.css'

const Error = () => {
    return (
        <div className="error-container mt-5">
          <h1>Pagina de error</h1>
        </div>
     );
}

export default Error;