import axios from 'axios';

export const checkUserRole = async (minRole, token) => {
  try {
    const response = await axios.post('https://api.elplanmadrid.org/rol', { token });
    const role = response.data.rol;

    // Verificar si el rol del usuario cumple con el rol mínimo aceptable
    if (role < minRole) {
      return false; // No cumple con el rol mínimo, devolver false
    }

    // Si cumple con el rol mínimo, devolver true
    return true;
  } catch (error) {
    console.error('Error al verificar el rol del usuario:', error);
    return false; // En caso de error, devolver false
  }
};

export const fetchEvents = async (token, all) => {
    try {
      const response = await axios.post('https://api.elplanmadrid.org/eventos', { token, all });
      if (response.data && Array.isArray(response.data.eventos)) {
        return response.data.eventos;
      } else {
        console.error('La respuesta del servidor no contiene la lista de eventos esperada.');
        return [];
      }
    } catch (error) {
      console.error('Hubo un error al obtener los eventos:', error);
      return [];
    }
  };
