import React from 'react';
import './Home.css';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";

const Home = () => {
    const { t } = useTranslation('home');

    return (
        <div className='home-container'>
            <div className="container mt-5">
                <div className="row align-items-center">
                    <div className="col-12 col-md-6 d-flex flex-column align-items-start text-left">
                        <h1 className='titulo'>{t('t1')}</h1>
                        <h3 className='subtitulo'>{t('t2')}</h3>
                        <NavLink to="/registro" id="boton" className="registro">{t('b1')}</NavLink>
                        <NavLink to="/masinfo" id="boton" className="masinfo">{t('b2')}</NavLink>
                    </div>
                    <div className="col-12 col-md-6 d-flex justify-content-center">
                        <img src="/images/Mascota.png" className="img-fluid custom-rounded" alt="Ejemplo" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
