import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './Logout.css';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Elimina el token del almacenamiento local
    localStorage.removeItem("token");
    // Emitir el evento de logout
    window.dispatchEvent(new Event('logout'));
    // Redirige al usuario a la página de inicio de sesión
    navigate("/login");
  }, [navigate]);

  return <div className="logout-container">Logging out...</div>;
};

export default Logout;
