import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const TablaResumen = ({ datos }) => {
  const { t } = useTranslation('tablaResumen');

  if (!datos || !datos.approvers || datos.approvers.length === 0) {
    return <p>{t('NoDatos')}</p>;
  }

  const { precio = 0, approvers } = datos;

  const usuarios = approvers.map(ap => ap.usuario);
  const metodosPago = ["paypal", "bizum", "transferencia", "gratuito"];

  // Calcular el total de entradas por usuario, incluyendo los gratuitos
  const totales = usuarios.reduce((acc, usuario) => {
    acc[usuario] = metodosPago.reduce((sum, metodo) => {
      const approver = approvers.find(ap => ap.usuario === usuario);
      return sum + (approver[metodo] || 0);
    }, 0);
    return acc;
  }, {});

  // Calcular el total en euros por usuario, excluyendo los métodos "gratuito"
  const totalesEuros = usuarios.reduce((acc, usuario) => {
    acc[usuario] = metodosPago.reduce((sum, metodo) => {
      const approver = approvers.find(ap => ap.usuario === usuario);
      if (metodo !== 'gratuito') {
        return sum + (approver[metodo] || 0) * precio;
      }
      return sum;
    }, 0);
    return acc;
  }, {});

  // Calcular el total teórico en euros, incluyendo también los gratuitos
  const totalesEurosConGratuito = usuarios.reduce((acc, usuario) => {
    acc[usuario] = metodosPago.reduce((sum, metodo) => {
      const approver = approvers.find(ap => ap.usuario === usuario);
      // Incluir también "gratuito" en el cálculo
      return sum + (approver[metodo] || 0) * precio;
    }, 0);
    return acc;
  }, {});

  return (
    <div className="table-responsive">
      <table className="table table-bordered text-center table-sm">
        <thead>
          <tr className="bg-primary text-white">
            <th className="metodo-pago-col">{t('MetodoPago')}</th>
            {usuarios.map((usuario, index) => (
              <th key={index} className="px-2">{usuario}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {metodosPago.map((metodo, index) => (
            <tr key={index}>
              <td className="metodo-pago-col">
                {metodo === 'transferencia'
                  ? 'Transf.'
                  : metodo.charAt(0).toUpperCase() + metodo.slice(1)}
              </td>
              {usuarios.map((usuario, idx) => {
                const approver = approvers.find(ap => ap.usuario === usuario);
                return (
                  <td key={idx}>{approver[metodo] || 0}</td>
                );
              })}
            </tr>
          ))}
          <tr className="bg-light text-dark">
            <td>{t('Total')}</td>
            {usuarios.map((usuario, index) => (
              <td key={index}>{totales[usuario]}</td>
            ))}
          </tr>
          <tr className="bg-info text-dark">
            <td>{t('TotalEuros')}</td>
            {usuarios.map((usuario, index) => (
              <td key={index}>
                {totalesEuros[usuario]} € ({totalesEurosConGratuito[usuario]} €)
              </td> // Mostrar el total en euros y el total incluyendo gratuitos
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

TablaResumen.propTypes = {
  datos: PropTypes.shape({
    precio: PropTypes.number,
    approvers: PropTypes.arrayOf(
      PropTypes.shape({
        usuario: PropTypes.string.isRequired,
        paypal: PropTypes.number,
        bizum: PropTypes.number,
        transferencia: PropTypes.number,
        gratuito: PropTypes.number,  // Añadimos el campo "gratuito"
      })
    ),
  }).isRequired,
};

export default TablaResumen;
